<template>
  <div>
    <el-dialog
      :title="orderItem.type === 1
                ? '仅退款'
                : orderItem.type === 2
                ? '退货退款'
                : '换货'"
      :visible.sync="show"
      width="700px"
      :before-close="closeDio"
    >
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="客户寄回物流公司:">
          <!-- <el-input v-model="form.expressCompanyCode" size="mini"></el-input> -->
          <el-select
            v-model="form.expressCompanyCode"
            filterable
            placeholder="请选择物流公司"
            size="mini"
            style="width:300px"
          >
            <el-option
              v-for="item in expressLists"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户寄回快递单号:">
          <el-input
            v-model="form.expressNo"
            style="width:300px"
            maxlength="20"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商家寄出物流公司:"
          v-if="orderItem && orderItem.type == 3"
        >
          <!-- <el-input v-model="form.shippingExpressCompanyCode" size="mini"></el-input> -->
          <el-select
            v-model="form.shippingExpressCompanyCode"
            filterable
            placeholder="请选择物流公司"
            size="mini"
            style="width:300px"
          >
            <el-option
              v-for="item in expressLists"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="商家寄出快递单号:"
          v-if="orderItem && orderItem.type == 3"
        >
          <el-input
            style="width:300px"
            v-model="form.shippingExpressNo"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDio" size="mini">取 消</el-button>
        <el-button type="primary" :loading="vLoading" @click="sumbit" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { userOrderApi, userDetailApi, userCouponApi, userBillApi,
//   modifyUserRefLog, userPointsApi, userSignLogApi, userHistoryApi, memberGrowthLog, userEditApi } from '@/api/user'
// import { verifyEmail } from '@/utils/toolsValidate';
import {} from "@/api/supplierGoods/after";
import { returnReturn,refundDetails } from "@/api/supplierGoods/after";
import { expressList } from "@/api/supplierGoods/order";
export default {
  name: "ReturnByUser",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderItem: {
      type: Object,
    },
  },
  data() {
    return {
      form: {},
      expressLists: [],
      vLoading:false,
    };
  },
  mounted() {
    this.getExpressLists();
    this.getOrderDetails()
  },
  methods: {
    // 获取物流列表
    getOrderDetails() {
      refundDetails(this.orderItem.id).then(async (res) => {
     
         if(res.data.receiver&&res.data.receiver.shippings.length>0){
          this.form.shippingExpressCompanyCode  = res.data.receiver.shippings[0].expressCompanyCode
          this.form.shippingExpressNo = res.data.receiver.shippings[0].expressNo
          this.$forceUpdate()
         }
         if(res.data.shipping&&res.data.shipping.shippings.length>0){
          this.form.expressCompanyCode  = res.data.shipping.shippings[0].expressCompanyCode
          this.form.expressNo = res.data.shipping.shippings[0].expressNo
          this.$forceUpdate()
         }
      });
    },
    async getExpressLists() {
      const { data } = await expressList();
      this.expressLists = data;
    },
    closeDio() {
      this.$parent.closeReturn();
    },
    sumbit() {
      this.form.refundId = this.orderItem.id;
      this.form.items = this.orderItem.refundItems;
      this.vLoading = true
      returnReturn(this.form).then((res) => {
        this.vLoading = false
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.closeDio();
        } 
      }).catch((err)=>{
        this.vLoading = false
      })
    },
  },
};
</script>

<style scoped lang="scss">
</style>
