<template>
  <div>
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
      <el-form-item label="售后单号：" prop="code">
          <el-input 
            v-model="tableFrom.code"
            placeholder="请输入售后单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="订单编号：" prop="orderCode">
          <el-input
            v-model="tableFrom.orderCode"
            placeholder="请输入订单编号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="下单人：" prop="nickname">
          <el-input
            v-model="tableFrom.nickname"
            placeholder="请输入下单人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>

        <el-form-item label="售后类型：" prop="type">
          <el-select v-model="tableFrom.type" size="medium" clearable class="input-with-select selWidth">
            <el-option
              v-for="item in statusTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售后状态：" prop="status">
          <el-select v-model="tableFrom.status" size="medium" clearable class="input-with-select selWidth">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFun()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="box-card">
      <el-button
        size="mini"
        style="margin-bottom: 5px"
        type="primary"
        @click="manualReturn()"
        >手动退款</el-button
      >
      <el-button
        size="mini"
        style="margin-bottom: 5px"
        type="primary"
        @click="uploadReturnType()"
        >修改售后类型</el-button
      >
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        ref="refsTable"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100" 
          label="序号"
        />
        <el-table-column :align="'center'" label="售后单号" prop="code">
        </el-table-column>
        <el-table-column :align="'center'" label="订单编号" prop="orderCode">
        </el-table-column>
        <el-table-column label="用户昵称" prop="nickname" align="center" />
        <el-table-column :align="'center'" label="订单ID" prop="orderId">
        </el-table-column>
        <el-table-column :align="'center'" label="售后原因" prop="reason">
        </el-table-column>
        <el-table-column :align="'center'" label="申请时间" prop="refundTime">
        </el-table-column>
        <el-table-column :align="'center'" label="售后状态">
          <template slot-scope="scope">
            {{ stateData[String(scope.row.status)] }}
          </template>
        </el-table-column>
        <el-table-column
          label="售后类型"
          :align="'center'"
          prop="type"
          min-width="50"
        >
          <template slot-scope="scope">
            {{
              scope.row.type === 1
                ? "仅退款"
                : scope.row.type === 2
                ? "退货退款"
                : "换货"
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="售后描述"
          :align="'center'"
          prop="remark"
          min-width="150"
        >
        </el-table-column>
        <el-table-column label="操作" :align="'center'">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              type="text"
              v-if="scope.row.status === 0"
              @click="onShipments(scope.row, 1)"
              >审核</el-button
            > -->
            <el-button
              type="text"
              size="mini"
              icon="el-icon-info"
              @click="onShipments(scope.row, 2)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <el-dialog
      :visible.sync="afterShow"
      :before-close="resetForm"
      size="55%"
      :title="title"
      :with-header="false"
    >
      <div style="margin-bottom: 10px;">
        <el-table
          :data="orderDetails.refundItems"
          size="small"
          row-key="id"
          :border="true"
          :default-expand-all="false"
        >
          <el-table-column :align="'center'" label="商品名称" prop="goodsName">
          </el-table-column>
          <el-table-column :align="'center'" label="规格图片">
            <template slot-scope="scope">
              <div class="demo-image__preview">
                <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : ''"
                  :preview-src-list="[
                    scope.row.goodsSkuImg ? scope.row.goodsSkuImg : '',
                  ]"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :align="'center'"
            label="商品规格"
            prop="goodsSkuName"
          ></el-table-column>
          <el-table-column :align="'center'" label="数量" prop="quantity">
          </el-table-column>

          <template
            v-if="
              orderDetails.refundItems.length > 0 &&
                orderDetails.refundItems[0].newGoodsSkuName
            "
          >
            <el-table-column
              :align="'center'"
              label="商品规格（新）"
              prop="newGoodsSkuName"
            >
            </el-table-column>
            <el-table-column :align="'center'" label="规格图片（新）">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    style="width: 36px; height: 36px"
                    :src="
                      scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''
                    "
                    :preview-src-list="[
                      scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : '',
                    ]"
                  />
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <el-form
        label-width="120px"
        :model="form"
        :rules="ruleList()"
        ref="ruleForm"
      >
        <el-form-item label="审核结果" prop="auditStatus">
          <el-radio-group v-model="form.auditStatus">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="商家拒绝描述"
          :prop="'remark'"
          v-if="form.auditStatus === 0"
        >
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button
            type="primary"
            :loading="loadings"
            @click="submitForm('ruleForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-drawer
      :visible.sync="afterDetailsShow"
      :before-close="closeDetails"
      ref="drawer"
      size="55%"
      :title="title"
      :with-header="false"
      v-if="afterDetailsShow"
    >
    <div v-loading="loading">
      <el-card>
        <el-descriptions :column="1" title="售后详情">
          <el-descriptions-item label="售后单号">{{
          orderDetails.code
        }}</el-descriptions-item>
        <el-descriptions-item label="订单号">{{
          orderDetails.orderCode
        }}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{
            orderDetails.refundTime
          }}</el-descriptions-item>
          <el-descriptions-item label="售后状态">{{
            stateData[String(orderDetails.status)]
          }}</el-descriptions-item>
          <el-descriptions-item label="售后类型">
            {{
              orderDetails.type === 1
                ? "仅退款"
                : orderDetails.type === 2
                ? "退货退款"
                : "换货"
            }}</el-descriptions-item
          >
          <el-descriptions-item
            label="退款金额"
            v-if="orderDetails.type === 1 || orderDetails.type === 2"
          >
            {{ orderDetails.realAmount }}</el-descriptions-item
          >
          <el-descriptions-item
            label="退款余额"
            v-if="orderDetails.type === 1 || orderDetails.type === 2"
          >
            {{ orderDetails.realBalance }}</el-descriptions-item
          >
          <el-descriptions-item
            label="退款积分"
            v-if="orderDetails.type === 1 || orderDetails.type === 2"
          >
            {{ orderDetails.realScore }}</el-descriptions-item
          >
          <el-descriptions-item label="售后描述">{{
            orderDetails.remark
          }}</el-descriptions-item>
          <el-descriptions-item
            v-if="orderDetails.status === 91"
            label="商家拒绝描述"
            >{{ orderDetails.rejectedRemark }}</el-descriptions-item
          >
          <div v-if="orderDetails.receiver">
            <el-descriptions-item label="买家姓名">{{
              orderDetails.receiver.name
            }}</el-descriptions-item>
            <el-descriptions-item label="买家收货手机号">{{
              orderDetails.receiver.mobile
            }}</el-descriptions-item>
            <el-descriptions-item label="买家收货地址">{{
              orderDetails.receiver.province +
                orderDetails.receiver.city +
                orderDetails.receiver.district +
                orderDetails.receiver.address
            }}</el-descriptions-item>
          </div>
        </el-descriptions>

        <div style="margin-bottom: 20px;">
          <div style="font-size: 14px;margin-bottom: 20px;">买家商品明细:</div>
          <el-table
            :data="orderDetails.refundItems"
            size="small"
            row-key="id"
            :default-expand-all="false"
          >
            <el-table-column
              :align="'center'"
              label="商品名称"
              prop="goodsName"
            >
            </el-table-column>
            <el-table-column :align="'center'" label="规格图片">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    style="width: 36px; height: 36px"
                    :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : ''"
                    :preview-src-list="[
                      scope.row.goodsSkuImg ? scope.row.goodsSkuImg : '',
                    ]"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column :align="'center'" label="换货规格图片">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    v-if="scope.row.newGoodsSkuImg"
                    style="width: 36px; height: 36px"
                    :src="scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''"
                    :preview-src-list="[
                      scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : '',
                    ]"
                  />
                  <div></div>
                </div> 
              </template>
            </el-table-column>
            <el-table-column
              :align="'center'"
              label="商品规格"
              prop="goodsSkuName"
            ></el-table-column>
            <el-table-column
              :align="'center'"
              label="换货商品规格"
              prop="newGoodsSkuName"
            ></el-table-column>
            <el-table-column :align="'center'" label="数量" prop="quantity">
            </el-table-column>
          </el-table>
        </div>
      </el-card>

      <el-card
        style="margin-top: 20px;"
        v-if="orderDetails.status === 21 && orderDetails.type == 3"
      >
        <template
          v-if="
            orderDetails.refundItems.length > 0 &&
              orderDetails.refundItems[0].newGoodsSkuName
          "
        >
          <div style="margin-bottom: 20px;">
            <div style="font-size: 14px;margin-bottom: 10px;">
              换货商品明细:
            </div>

            <el-switch
              style="margin-bottom: 20px;"
              v-model="isShow"
              active-text=""
              inactive-text="拆包发货"
            >
            </el-switch>
            <el-table
              :data="orderDetails.refundItems"
              size="small"
              row-key="id"
              :border="true"
              :default-expand-all="false"
            >
              <el-table-column
                :align="'center'"
                label="商品名称"
                prop="goodsName"
              >
              </el-table-column>
              <el-table-column :align="'center'" label="规格图片">
                <template slot-scope="scope">
                  <div class="demo-image__preview">
                    <el-image
                      style="width: 36px; height: 36px"
                      :src="
                        scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''
                      "
                      :preview-src-list="[
                        scope.row.newGoodsSkuImg
                          ? scope.row.newGoodsSkuImg
                          : '',
                      ]"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :align="'center'"
                label="商品规格"
                prop="newGoodsSkuName"
              >
              </el-table-column>
              <el-table-column :align="'center'" label="数量">
                <template slot-scope="scope">
                  <div v-if="!isShow">{{ scope.row.surplusNum }}</div>
                  <el-input-number
                    v-model="scope.row.surplusNum"
                    v-else
                    :min="1"
                    :max="scope.row.quantity - scope.row.receiverQuantity"
                    label=""
                  ></el-input-number>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div style="font-size: 14px;margin-bottom: 10px;">换货物流</div>
          <el-form
            ref="expressForms"
            :model="expressForm"
            :rules="rules"
            label-width="85px"
            size="mini"
          >
            <el-form-item label="物流公司" prop="expressCompanyCode">
              <el-select
                v-model="expressForm.expressCompanyCode"
                filterable
                placeholder="请选择物流公司"
              >
                <el-option
                  v-for="item in expressLists"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="物流单号" prop="expressNo">
              <el-input
                v-model="expressForm.expressNo"
                style="width: 30%;"
                maxlength="20"
                placeholder="请填写物流单号"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <div style="display:flex;  justify-content: flex-end;">
                <el-button
                  type="primary"
                  :loading="loadings"
                  @click="submitExpress('forms')"
                  >确定发货</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-card>

      <el-card
      style="margin-top: 20px;position: relative;"
        v-if="orderDetails.shipping && orderDetails.shipping.shippings.length"
      >
        <el-button type="primary" class="btn"     v-if="orderDetails.status == 20" :loading="loadings" @click="submitOrder"
          >确定收货</el-button
        >
        <el-descriptions :column="1" :title="`用户物流信息`">
          <el-descriptions-item label="物流公司">{{
            orderDetails.shipping.shippings[0].expressCompany
          }}</el-descriptions-item>
          <el-descriptions-item label="物流单号">{{
            orderDetails.shipping.shippings[0].expressNo
          }}</el-descriptions-item>
        </el-descriptions>

        <el-steps direction="vertical" :active="1" space="50px">
          <el-step
            v-for="(i, index) in orderDetails.shipping.shippings[0].traces"
            :key="index"
            :description="i.AcceptStation"
            :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
          ></el-step>
        </el-steps>
      </el-card>

      <el-card
        style="margin-top: 20px;"
        v-if="orderDetails.receiver && orderDetails.receiver.shippings.length"
      >
        <el-radio-group v-model="expressNum" style="margin-bottom: 30px;">
          <el-radio-button
            :label="index"
            v-for="(item, index) in orderDetails.receiver.shippings"
            :key="index"
            >{{ "包裹" + (index + 1) }}</el-radio-button
          >
        </el-radio-group>

        <el-descriptions :column="1" :title="`商家发货明细`">
          <el-descriptions-item label="物流公司">{{
            orderDetails.receiver.shippings[expressNum].expressCompany
          }}</el-descriptions-item>
          <el-descriptions-item label="物流单号">{{
            orderDetails.receiver.shippings[expressNum].expressNo
          }}
        </el-descriptions-item>
        </el-descriptions>
        <el-table
          :data="orderDetails.receiver.shippings[expressNum].shippingItems"
          size="small"
          row-key="id"
          :border="true"
          :default-expand-all="false"
        >
          <el-table-column :align="'center'" label="商品名称" prop="goodsName">
          </el-table-column>
          <el-table-column :align="'center'" label="规格图片">
            <template slot-scope="scope">
              <div class="demo-image__preview">
                <el-image
                  style="width: 36px; height: 36px"
                  :src="
                    scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''
                  "
                  :preview-src-list="[
                    scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : '',
                  ]"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :align="'center'"
            label="商品规格"
            prop="newGoodsSkuName"
          >
          </el-table-column>
          <el-table-column :align="'center'" label="发货数量" prop="quantity">
          </el-table-column>
        </el-table>
        <el-steps direction="vertical" :active="1" space="50px" style="margin-top: 20px;">
          <el-step
            v-for="(i, index) in orderDetails.receiver.shippings[0].tracesCopy"
            :key="index"
            :description="i.AcceptStation"
            :icon="true ? 'el-icon-document-checked' : 'el-icon-success'"
          ></el-step>
        </el-steps>
      </el-card>

      <!-- <div
        v-if="orderDetails.status == 20"
        style="display:flex;  justify-content: flex-end;margin-top: 20px;"
      >
        <el-button type="primary" :loading="loadings" @click="submitOrder"
          >确定收货</el-button
        >
      </div> -->
    </div>
    </el-drawer>
    <ReturnByUser v-if="showReturn" :orderItem="selectionOne[0]" :show="showReturn" />
  </div>
</template>

<script>
import { afterState } from "./orders";
import {
  list,
  refundAudit,
  refundDetails,
  afterPageList,
  refundConfirm,
  refundShipping,
  refundUpdateType
} from "@/api/supplierGoods/after";
import { expressLogistics } from "@/api/supplierGoods/order";
import { expressList } from "@/api/supplierGoods/order";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import timeOptions from "@/assets/utils/timeOptions";
import {getIndex} from "@/utils/other";
import { exportExcel } from "@/assets/utils/exportExcel";
import ReturnByUser from "../order/components/returnByUser";
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
    ReturnByUser
  },
  data() {
    return {
      showReturn:false,
      selectionOne:[],
      loading:false,
      isShow: false,
      userExpressList: [],
      shangExpressList: [],
      afterDetailsShow: false,
      title: "",
      afterList: [], //售后原因列表
      statusTypeList: [
      {
          label: "仅退款",
          value: 1,
        },
        {
          label: "退货退款",
          value: 2,
        },
        {
          label: "换货",
          value: 3,
        },
      ],
      expressNum: 0,
      statusList: [
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "待用户退货",
          value: 10,
        },
        {
          label: "商家待收货",
          value: 20,
        },
        {
          label: "商家待发货",
          value: 21,
        },
        {
          label: "退款中",
          value: 30,
        },
        {
          label: "用户待收货",
          value: 40,
        },
        {
          label: "已完成",
          value: 50,
        },
        {
          label: "已关闭",
          value: 90,
        },
        {
          label: "商家拒绝",
          value: 91,
        },
      ],
      stateData: afterState, //状态对象数据
      pickerOptions: timeOptions,
      tabPosition: 0,
      orderDetails: {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      },
      activeName: "first", //tab切换默认
      afterShow: false,
      type: 1,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "",
        type: "",
        code:"",
        orderCode:"",
        nickname:"",
      },
      title: "新增分类",
      form: { remark: "", auditStatus: 1 },
      value: [],
      loadings: false,
      expressForm: {
        expressCompanyCode: "",
        expressNo: "",
      },
      expressLists: [],
      rules: {
        expressCompanyCode: [
          { required: true, message: "请选择物流公司：", trigger: "blur" },
        ],
        expressNo: [
          { required: true, message: "请填写物流单号", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getExpressLists();
    this.getList();
  },
  methods: {
    closeReturn(){
    this.showReturn = false
    this.getList();
    },
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据 
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },
    uploadReturnType(){
      if (!this.selectionOne.length) {
        this.$message.error("请选择数据");
        return;
      }
      this.$confirm("是否修改售后类型?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        refundUpdateType({refundId:this.selectionOne[0].id})
          .then(({ message }) => {
              this.getList()
          })
          .catch(({ message }) => {
            // this.$message.error(message);
          });
      });
    },
    manualReturn() {
      if (!this.selectionOne.length) {
        this.$message.error("请选择数据");
        return;
      }
      if(this.selectionOne[0].status>=50){
        this.$message.error("该状态不支持退款");
        return;
      }
      this.$confirm("是否确认已走完退款流程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.showReturn = true
      });
    },
    exportFun() {
      exportExcel("/order/web/refund/pageListExport", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "售后订单列表",
      });
    },
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    // 获取物流列表
    async getExpressLists() {
      console.log('1231---')
      const { data } = await expressList();
      this.expressLists = data;
    },
    // 换货确认发货
    submitExpress() {
      this.$refs["expressForms"].validate(async (valid) => {
        if (valid) {
          this.loadings = true;
          let items = this.orderDetails.refundItems.map((val) => {
            return {
              goodsId: val.goodsId,
              goodsSkuId: val.goodsSkuId,
              quantity: val.surplusNum,
            };
          });
          let res = await refundShipping({
            items,
            ...this.expressForm,
            refundId: this.orderDetails.id,
          });
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getList();
            this.isShow = false;
            this.$refs["expressForms"].resetFields();
            this.expressForm.expressCompanyCode = "";
            this.expressForm.expressNo = "";
          }

          this.loadings = false;
        } else {
          return false;
        }
      });
    },
    // 确认收货
    submitOrder() {
      this.$confirm("确认收到当前用户退货吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const res = await refundConfirm({ refundId: this.orderDetails.id });
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    ruleList() {
      return {
        auditStatus: [
          { required: true, message: "请选择售后状态", trigger: "blur" },
        ],
        remark: [
          {
            required: this.form.auditStatus === 0 ? true : false,
            message: "请输入商家拒绝描述",
            trigger: "blur",
          },
        ],
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loadings = true;
          let res = await refundAudit({
            ...this.form,
            refundId: this.orderDetails.id,
          });
          if (res.code === 200) {
            this.loadings = false;
            this.$message.success(res.msg);
            this.resetForm();
            this.getList();
          } else {
            this.loadings = false;
            this.resetForm();
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.afterShow = false;
      this.orderDetails = {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      }
    },
    closeDetails() {
      this.afterDetailsShow = false;
      this.orderDetails = {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      }
    },
    // 售后原因列表
    // async getafterPageList() {
    //   const res = await afterPageList({type:2,pageSize:1000})
    //   if(res.code == 200) {
    //     this.afterList = res.data
    //   }
    // },
    getList() {
      this.listLoading = true;
      list(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 编辑
    onShipments(row, type) {
      this.loading = true
      this.type = type;
      this.title =
        type === 1
          ? row.type === 1
            ? "仅退款审核"
            : row.type === 2
            ? "退货退款审核"
            : "换货审核"
          : "售后详情";
      this.getOrderDetails(row);
      if (type === 1) {
        this.afterShow = true;
      } else if (type === 2) {
        this.afterDetailsShow = true;
      }
    },
    // 售后详情
    getOrderDetails(row) {
      refundDetails(row.id).then(async (res) => {
        if (res.data.refundItems.length) {
          res.data.refundItems.forEach((val) => {
            val.surplusNum = val.quantity - val.receiverQuantity;
          });
        }
        if (res.data.receiver && res.data.receiver.shippings.length) {
          res.data.receiver.shippings.map(async(item,index) => {
            res.data.receiver.shippings[index].tracesCopy = await this.getExpressLogistics(2,item.id,res.data.type);
          });
        }
        if (res.data.shipping && res.data.shipping.shippings.length) {
          res.data.shipping.shippings[0].traces = await this.getExpressLogistics(1,res.data.shipping.shippings[0].id,res.data.type);
        }
        setTimeout(()=>{
          this.orderDetails = res.data;
          this.loading = false
        },1000)
      });
    },
    async getExpressLogistics(type,id,locType) {
      const res = await expressLogistics({
        type: locType,
        id
      }); 
      if (type === 1) {
        return res.data.traces !== '暂无轨迹信息' ? JSON.parse(res.data.traces) : [];
      }
      if (type === 2) {
        return res.data.traces !== '暂无轨迹信息'
          ? JSON.parse(res.data.traces)
          : [];
      }
    },
    getCountAll() {
      if (this.orderDetails.orderItems) {
        let num = 0;
        this.orderDetails.orderItems.map((val) => {
          num += val.quantity;
        });
        return num;
      }
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.tableFrom.pageNum = 1
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.headers {
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .orderno {
    font-weight: 500;
    font-size: 14px;
    color: #606266;
  }
}
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 30px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.btn{
  position: absolute;
  top: 10px;
  right: 20px;
}
</style>
